import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias19 from "../../../../static/img/blog/noticias-19-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Un vistazo a las perspectivas de 2021'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias19}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                20 Abril. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Abigail Ciau
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Un vistazo a las perspectivas de 2021</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>El 2020 fue uno de los años más complicados de la historia, con el surgimiento de la pandemia por el COVID-19
                        y su profundo impacto en la economía global, que conllevó a la peor recesión desde la Segunda Guerra Mundial.
                        Por lo anterior, la mira está puesta sobre el ejercicio 2021, ya que fungirá como el punto de inflexión que
                        dictará que tan rápido será el retorno a la “normalidad” y por ende la recuperación económica.</p>

                        <p>En este respecto, a continuación, se presentan las principales perspectivas que analistas e instituciones tienen para 2021:</p>

                        <p><em><b>• PIB:</b></em> Una de las consecuencias más notorias que trajo consigo el COVID-19
                        fueron las restricciones que se impusieron a las actividades económicas, mismas que derivaron en contracciones
                        del PIB de diversos países a finales de 2020. Si bien, aún es pronto para inferir cuando se regresará al ritmo
                        de la economía previo a la pandemia, el Fondo Monetario Internacional estima que el crecimiento mundial será de
                        un 6% en 2021; mientras que, para México, se espera un alza de 5%, siendo el despliegue eficiente de vacunas uno
                        de los principales factores que se prevé contribuya a alcanzar dicho objetivo, así como el apoyo derivado de la
                        actividad de su mayor socio comercial, Estados Unidos, el cual se estima tenga un crecimiento de alrededor de 6.4%. </p>

                        <p><em><b>• Inflación:</b></em> En seguimiento a un mayor nivel de productividad en las empresas,
                        favorecidas por el relajamiento de las medidas de confinamiento, las expectativas de la inflación general para
                        el final de 2021, por parte de analistas consultados por el Banco de México, se situó en 4.17% (+52 pbs. vs 3.65%
                          de enero 2021). A pesar de que podría salirse del rango de 3% (+/- 1 punto porcentual), se espera que la
                          trayectoria de la inflación a lo largo del año se mantenga cerca del rango y sea congruente con la política
                          monetaria para el cierre del ejercicio 2021.</p>

                        <p><em><b>• Comercio electrónico:</b></em> En 2020 el comercio electrónico o <em>e-commerce </em>
                        presentó un auge importante tras las restricciones impuestas en materia de movilidad, de manera que, de acuerdo
                        con el INEGI, el 6% del PIB de 2020 estuvo compuesto por este rubro vs. 3% en 2013. En este sentido, la variedad
                        de herramientas digitales jugó un papel importante para todas aquellas empresas que decidieron adecuar su giro
                        al <em>e-commerce</em>; por lo anterior, no es de dudar que esta tendencia continuará al alza ante los diversos beneficios
                        que conlleva, tal que se prevé que para el 2021 la aportación al PIB de México ascienda a 9%.</p>

                        <p><em><b>•	Home Office:</b></em> Las restricciones de movilidad derivaron en la implementación
                        del trabajo en casa (<em>Home Office</em>), tendencia que se convirtió es un aspecto importante para mantener la continuidad
                        de la operación de las empresas. Si bien, se espera que la modalidad permanezca durante todo el 2021, dados los
                        beneficios que brinda a las compañías (reducción de costos, etc.) y los colaboradores (ahorro de tiempo y gastos de
                        transporte, etc.), se espera el despliegue de un modelo mixto en los siguientes años (trabajo a distancia y presencial),
                        pues los espacios de oficina proporcionan ventajas importantes en materia de eficiencia y aprendizaje. </p>



                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Fondo Monetario internacional (2021). Perspectivas de la economía mundial: Manejar recuperaciones divergentes. Washington, DC, abril. <a href="https://www.imf.org/es/Publications/WEO/Issues/2021/03/23/world-economic-outlook-april-2021" target="_blank">FMI</a>.</li>
                            <li>Banco de México (2021). Encuesta sobre las Expectativas de los Especialistas en Economía del Sector Privado: Marzo de 2021. Abril 5, 2021. <a href="https://www.banxico.org.mx/publicaciones-y-prensa/encuestas-sobre-las-expectativas-de-los-especialis/%7BA9302B4A-04AD-4551-9877-028346F57E96%7D.pdf" target="_blank">BANXICO</a>.</li>
                            <li>Zimmerman, E. (2021). e-commerce, con 9% del PIB en 2021; superará a rama automotriz. Marzo 3, 2021. <a href="http://realestatemarket.com.mx/noticias/mercado-inmobiliario/32208-e-commerce-con-9-del-pib-en-2021-superara-a-sector-automotriz" target="_blank">Real Estate Market</a>.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
